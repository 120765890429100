export const locations = [
  "Ang Mo Kio",
  "Bedok",
  "Benoi",
  "Bishan",
  "Boon Lay",
  "Bukit Batok",
  "Bukit Merah",
  "Bukit Panjang",
  "Bukit Timah",
  "Changi",
  "Choa Chu Kang",
  "Clementi",
  "Downtown Core",
  "Geylang",
  "Ghim Moh",
  "Holland Road",
  "Hougang",
  "Jurong Island",
  "Jurong",
  "Kallang",
  "Kent Ridge",
  "Lim Chu Kang",
  "Mandai",
  "Marine Parade",
  "Nanyang",
  "Newton",
  "North-Eastern Islands",
  "Novena",
  "Orchard",
  "Outram",
  "Pandan Gardens",
  "Pasir Ris",
  "Paya Lebar",
  "Pioneer",
  "Pioneer",
  "Punggol",
  "Queenstown",
  "River Valley",
  "Rochor",
  "Seletar",
  "Sembawang",
  "Sengkang",
  "Sentosa",
  "Serangoon",
  "Simpang",
  "Southern Islands",
  "Sungei Kadut",
  "Tampines",
  "Tanglin",
  "Teban Gardens",
  "Tengah",
  "Thomson",
  "Toa Payoh",
  "Toh Tuck",
  "Tuas",
  "West Coast",
  "Western Islands",
  "Woodlands",
  "Yishun",
];
