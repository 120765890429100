import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import { enter, leave, toggle } from "el-transition";
export default class extends Controller {
  static targets = ["entries", "pagination"];

  connect() {
    this.createObserver();

    // var x = document.getElementById("loadingspinner");
    // var y = document.getElementById("endofpagetext");
    // x.style.display = "none";
    // y.style.display = "none";
  }

  createObserver() {
    const observer = new IntersectionObserver(
      (entries) => this.handleIntersect(entries),
      {
        threshold: [0, 1.0],
      }
    );
    observer.observe(this.paginationTarget);
  }
  handleIntersect(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.loadMore();
      }
    });
  }

  disconnect() {
    observer.unobserve(this.paginationTarget);
  }

  loadMore() {
    // var x = document.getElementById("loadingspinner");
    // var y = document.getElementById("endofpagetext");
    // x.style.display = "block";
    // console.log("Load more");
    let next_page = this.paginationTarget.querySelector("a[rel='next']");
    // console.log("next page ", next_page);
    if (next_page == null) {
      // console.log("nothing");
      // x.style.display = "none";
      // y.style.display = "block";
      return;
    }
    let url = next_page.href;
    // console.log("url", url);

    Rails.ajax({
      type: "GET",
      url: url,
      dataType: "json",
      success: (data) => {
        console.log(data);
        this.entriesTarget.insertAdjacentHTML("beforeend", data.entries);
        this.paginationTarget.innerHTML = data.pagination;
      },
      error: (err) => {},
    });
  }
}
