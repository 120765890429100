import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["toggleable", "trigger"];
  connect() {}
  // toggle() {
  //   this.toggleableTarget.classList.toggle("hidden");
  // }
  toggle() {
    if (this.triggerTarget.getAttribute("aria-expanded") == "false") {
      this.show();
    } else {
      this.hide(null);
    }
  }

  show() {
    this.triggerTarget.setAttribute("aria-expanded", "true");
    this.toggleableTarget.classList.remove("hidden");
  }

  hide(event) {
    if (
      event &&
      (this.triggerTarget.contains(event.target) ||
        this.toggleableTarget.contains(event.target))
    ) {
      return;
    }

    this.triggerTarget.setAttribute("aria-expanded", "false");
    this.toggleableTarget.classList.add("hidden");
  }
}
