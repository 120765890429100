import Dropzone from "dropzone";
import { Controller } from "stimulus";
import { DirectUpload } from "@rails/activestorage";
import {
  getMetaValue,
  toArray,
  findElement,
  removeElement,
  insertAfter,
} from "helpers";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.dropZone = createDropZone(this);
    console.log(this.dropZone);
    this.hideFileInput();
    this.bindEvents();
    Dropzone.autoDiscover = false; // necessary quirk for Dropzone error in console
  }

  // Private
  hideFileInput() {
    this.inputTarget.disabled = true;
    this.inputTarget.style.display = "none";
  }

  bindEvents() {
    this.dropZone.on("drop", (file) => {
      console.log("file dropped");
    });
    function base64ToFile(dataURI, origFile) {
      var byteString, mimestring;

      if (dataURI.split(",")[0].indexOf("base64") !== -1) {
        byteString = atob(dataURI.split(",")[1]);
      } else {
        byteString = decodeURI(dataURI.split(",")[1]);
      }

      mimestring = dataURI.split(",")[0].split(":")[1].split(";")[0];

      var content = new Array();
      for (var i = 0; i < byteString.length; i++) {
        content[i] = byteString.charCodeAt(i);
      }

      var newFile = new File([new Uint8Array(content)], origFile.name, {
        type: mimestring,
      });

      // Copy props set by the dropzone in the original file

      var origProps = [
        "upload",
        "status",
        "previewElement",
        "previewTemplate",
        "accepted",
      ];

      $.each(origProps, function (i, p) {
        newFile[p] = origFile[p];
      });

      return newFile;
    }

    // this.dropZone.on("addedfile", (origFile) => {
    //   console.log("added file this", this);

    //   console.log("orig file", origFile);
    //   var dropZone = this.dropZone;
    //   console.log(this);
    //   var MAX_WIDTH = 800;
    //   var MAX_HEIGHT = 600;

    //   var reader = new FileReader();

    //   // Convert file to img

    //   reader.addEventListener("load", function (event) {
    //     var origImg = new Image();
    //     origImg.src = event.target.result;

    //     origImg.addEventListener("load", function (event) {
    //       var width = event.target.width;
    //       var height = event.target.height;

    //       // Don't resize if it's small enough

    //       if (width <= MAX_WIDTH && height <= MAX_HEIGHT) {
    //         dropZone.enqueueFile(origFile);
    //         return;
    //       }
    //       // Calc new dims otherwise

    //       if (width > height) {
    //         if (width > MAX_WIDTH) {
    //           height *= MAX_WIDTH / width;
    //           width = MAX_WIDTH;
    //         }
    //       } else {
    //         if (height > MAX_HEIGHT) {
    //           width *= MAX_HEIGHT / height;
    //           height = MAX_HEIGHT;
    //         }
    //       }

    //       // Resize

    //       var canvas = document.createElement("canvas");
    //       // console.log(canvas);
    //       canvas.width = width;
    //       canvas.height = height;

    //       var ctx = canvas.getContext("2d");
    //       ctx.drawImage(origImg, 0, 0, width, height);

    //       var resizedFile = base64ToFile(canvas.toDataURL(), origFile);

    //       // Replace original with resized

    //       var origFileIndex = dropZone.files.indexOf(origFile);
    //       console.log("origFileIndex", origFileIndex);
    //       dropZone.files[origFileIndex] = resizedFile;
    //       console.log(dropZone.files);

    //       resizedFile.previewElement.addEventListener("click", function () {
    //         dropZone.removeFile(resizedFile);
    //         // dropZone.removeFile(resizedFile);
    //       });

    //       // origFile.previewElement.addEventListener("click", function () {
    //       //   dropZone.removeFile(origFile);
    //       //   // dropZone.removeFile(resizedFile);
    //       // });

    //       // Enqueue added file manually making it available for
    //       // further processing by dropzone

    //       // dropZone.enqueueFile(resizedFile);
    //       // console.log("inside resize this", this);

    //       setTimeout(() => {
    //         resizedFile.accepted &&
    //           createDirectUploadController(dropZone, resizedFile).start();
    //       }, 500);

    //       // console.log(origFile);
    //       // console.log(resizedFile);
    //     });
    //   });

    //   reader.readAsDataURL(origFile);
    // });

    this.dropZone.on("addedfile", (file) => {
      console.log("added file");
      // console.log(`File added: ${file.name}`);

      // console.log(this.dropZone);

      var p = this.dropZone;
      file.previewElement.addEventListener("click", function () {
        p.removeFile(file);
      });

      // console.log(this);
      // p.options.resizeWidth = 300;
      // this.resizeWidth = 300;
      // p.options.myDropzone = {
      //   resizeWidth: 200,
      // };
      // this.dropZone.
      // console.log(p.options);

      setTimeout(() => {
        file.accepted && createDirectUploadController(this, file).start();
      }, 500);
    });

    this.dropZone.on("removedfile", (file) => {
      console.log("REMOVED FILE", file);
      // this.dropZone.files = [];
      console.log(this.dropZone.files);
      file.controller && removeElement(file.controller.hiddenInput);
    });

    this.dropZone.on("canceled", (file) => {
      file.controller && file.controller.xhr.abort();
    });
  }

  get headers() {
    return { "X-CSRF-Token": getMetaValue("csrf-token") };
  }

  get url() {
    return this.inputTarget.getAttribute("data-direct-upload-url");
  }

  get maxFiles() {
    return this.data.get("maxFiles") || 1;
  }

  get maxFileSize() {
    return this.data.get("maxFileSize") || 256;
  }

  get acceptedFiles() {
    return this.data.get("acceptedFiles") || "image/*";
  }

  get addRemoveLinks() {
    return this.data.get("addRemoveLinks") || true;
  }

  get resizeWidth() {
    return this.data.get("resizeWidth") || 1024;
  }
}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.url, this);
    this.source = source;
    this.file = file;
  }

  start() {
    this.file.controller = this;
    this.hiddenInput = this.createHiddenInput();
    this.directUpload.create((error, attributes) => {
      if (error) {
        removeElement(this.hiddenInput);
        this.emitDropzoneError(error);
      } else {
        this.hiddenInput.value = attributes.signed_id;
        this.emitDropzoneSuccess();
      }
    });
  }

  createHiddenInput() {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = this.source.inputTarget.name;
    insertAfter(input, this.source.inputTarget);
    return input;
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr);
    this.emitDropzoneUploading();
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr;
    this.xhr.upload.addEventListener("progress", (event) =>
      this.uploadRequestDidProgress(event)
    );
  }

  uploadRequestDidProgress(event) {
    const element = this.source.element;
    const progress = (event.loaded / event.total) * 100;
    findElement(
      this.file.previewTemplate,
      ".dz-upload"
    ).style.width = `${progress}%`;
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING;
    this.source.dropZone.emit("processing", this.file);
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR;
    this.source.dropZone.emit("error", this.file, error);
    this.source.dropZone.emit("complete", this.file);
  }

  emitDropzoneSuccess() {
    console.log("successful upload");
    this.file.status = Dropzone.SUCCESS;
    this.source.dropZone.emit("success", this.file);
    this.source.dropZone.emit("complete", this.file);
  }
}

function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file);
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller);
}

function createDropZone(controller) {
  return new Dropzone(controller.element, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    // acceptedFiles: ".png, .jpeg, .jpg",
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: controller.addRemoveLinks,
    resizeHeight: 500,
    resizeWidth: 500,
    resizeMethod: "contain",
    resizeQuality: 0.1,
    autoQueue: false,
    // init: function () {},
  });
}
