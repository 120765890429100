import { Controller } from "stimulus";
import autocomplete from "autocomplete.js";
import { locations } from "../utils/locations.js";

export default class extends Controller {
  static targets = ["field"];

  search(query, callback) {
    let inputArr = locations;

    let filteredData = inputArr.filter((val) => {
      return val.toLowerCase().includes(query.toLowerCase());
    });

    callback(filteredData);
    // callback([{ name: "Hello", name: "another" }]);
  }

  connect() {
    this.ac = autocomplete(this.fieldTarget, { hint: false }, [
      {
        source: this.search,
        debounce: 100,
        render: true,
        templates: {
          suggestion: function (suggestion) {
            return suggestion;
          },
        },
      },
    ]).on("autocomplete:selected", (event, suggestion, dataset, context) => {
      this.ac.autocomplete.setVal(suggestion);
    });
  }
}
